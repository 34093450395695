import React, { useState } from 'react'
import Layout from '../components/Layout';
import PaddedLayout from '../components/PaddedLayout';
import "../styles.scss"
import './contact.scss'
export default function Contact() {
    return (
        <Layout>
                        <div className="contact-header">
                <h4>Contact <span className="blue">Tomcat</span></h4>
            </div>
            <PaddedLayout>
                <div className="contact-info">
                <h4>RPS Corporation</h4>
                <p>1711 South Street </p>
                <p>Racine WI, USA</p>
                </div>
                <div className="contact-info">
                <p>Toll Free: 1-800-123-1234</p>
                <p>Phone: 800-123-1234</p>
                <p>Fax: 1-800-123-1234</p>
                <p>Email: <a href="mailto: someEmail@mail.com">someEmail@mail.com</a></p>
                </div>
                <div className="contact-info">
                <h4>Tomcat Customer Care</h4>
                <p>Direct: 800-123-1234</p>
                <p>Email: <a href="mailto: someEmail@mail.com">someEmail@mail.com</a></p>
                </div>
                <div className="contact-info">
                <h4>Marketing</h4>
                <p>Email: <a href="mailto: someEmail@mail.com">someEmail@mail.com</a></p>
                </div>
                <div className="contact-info">
                <h4>Sales</h4>
                <p>Email: <a href="mailto: someEmail@mail.com">someEmail@mail.com</a></p>
                </div>
                <div className="contact-info">
                <h4>Human Resources</h4>
                <p>Email: <a href="mailto: someEmail@mail.com">someEmail@mail.com</a></p>
                </div>
            </PaddedLayout>
        </Layout>
    )
}
